<template>
  <div class="noticeDetail">
    <div class="titleTop">
      <img @click="goBack()" src="../assets/back.png" alt="" />
      <h1>公告详情</h1>
    </div>
    <main>
      <div class="title">{{ noticeItem.title }}</div>
      <div class="time">{{ noticeItem.create_time }}</div>
      <p>{{ noticeItem.desc }}</p>
    </main>
  </div>
</template>
<script>
import { getNotice } from "../api/index.js";
export default {
  setup() {},
  data() {
    return {
      index: "",
      noticeData: [],
      noticeItem: {},
    };
  },
  mounted() {
    this.index = this.$route.query.index;
    this.getNoticeData(this.index);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getNoticeData(index) {
      let params = {};
      getNotice(params).then((res) => {
        this.noticeData = res.data;
        this.noticeItem = this.noticeData[index];
        console.log(this.noticeItem);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.noticeDetail {
  .titleTop {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #fff;
    img {
      width: 16px;
      position: absolute;
      left: 18px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    h1 {
      line-height: 40px;
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
  }
  main {
    padding: 15px 25px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .time {
      font-size: 12px;
      color: #999999;
      text-align: center;
      margin-top: 1px;
    }
    p {
      margin-top: 15px;
      font-size: 14px;
      color: #666666;
    }
  }
}
</style>